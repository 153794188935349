<template>
  <section id="myInformation" class="flex flex-col gap-y-8 lg:gap-y-12 h-full">
    <div>
      <div class="flex lg:hidden flex-row bg-white py-3">
        <svg
          class="cursor-pointer"
          @click="openMobileNav"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#111"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 18l-6-6 6-6"
          />
        </svg>
        <div class="flex-1 flex justify-center font-EffraM text-center text-lg">
          Modifer mon compte
        </div>
      </div>
      <nav
        class="bg-white overflow-x-scroll lg:overflow-x-visible flex flex-row px-0 lg:px-8 overflow-hidden"
      >
        <a
          href="#"
          class="flex items-center gap-x-2 font-EffraR text-lg py-3 px-2 whitespace-nowrap"
          :class="
            page === 1
              ? 'text-rosDok-ultra border-0 border-b-2 border-solid border-rosDok-ultra'
              : 'text-grayDok-sidebar border-0 border-b-2 border-solid border-grayborder'
          "
          @click="page = 1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <g clip-path="url(#clip0)">
              <path
                :stroke="page === 1 ? '#FD5B68' : '#828282'"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 18.333c4.602 0 8.333-3.73 8.333-8.333 0-4.602-3.73-8.333-8.333-8.333-4.602 0-8.333 3.73-8.333 8.333 0 4.602 3.73 8.333 8.333 8.333zM10 13.333V10M10 6.667h.008"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <path fill="#fff" d="M0 0H20V20H0z" />
              </clipPath>
            </defs>
          </svg>
          Information générale
        </a>
        <a
          href="#"
          class="flex items-center gap-x-2 font-EffraR text-lg py-3 px-2 whitespace-no-wrap"
          :class="
            page === 2
              ? 'text-rosDok-ultra border-0 border-b-2 border-solid border-rosDok-ultra'
              : 'text-grayDok-sidebar border-0 border-b-2 border-solid border-grayborder'
          "
          @click="page = 2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              :stroke="page === 2 ? '#FD5B68' : '#828282'"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 18.333S16.667 15 16.667 10V4.167L10 1.667l-6.667 2.5V10c0 5 6.667 8.333 6.667 8.333z"
            />
          </svg>
          Assurance
        </a>
        <a
          href="#"
          class="flex items-center gap-x-2 font-EffraR text-lg  py-3 px-2 whitespace-no-wrap"
          :class="
            modal.is
              ? 'text-rosDok-ultra border-0 border-b-2 border-solid border-rosDok-ultra'
              : 'text-grayDok-sidebar border-0 border-b-2 border-solid border-grayborder'
          "
          @click="modal.is = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              :stroke="modal.is ? '#FD5B68' : '#828282'"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17.867 9.208l-7.659 7.659c-.938.938-2.21 1.465-3.537 1.465s-2.6-.527-3.538-1.465c-.938-.939-1.465-2.211-1.465-3.538s.527-2.6 1.465-3.537l7.659-7.659c.625-.625 1.473-.977 2.358-.977s1.733.352 2.358.977c.626.626.977 1.474.977 2.359 0 .884-.351 1.733-.977 2.358l-7.666 7.658c-.313.313-.737.489-1.18.489-.442 0-.866-.176-1.179-.489-.312-.312-.488-.736-.488-1.179 0-.442.176-.866.488-1.179l7.075-7.067"
            />
          </svg>
          Documents
        </a>
      </nav>
    </div>

    <phone-activation />

    <div
      class="grid gap-x-8 gap-y-5 lg:gap-y-8 grid-cols-1 lg:grid-cols-2 w-full px-4 lg:px-8"
    >
      <!--------General info---------->
      <template v-if="page === 1">
        <dok-input
          label="Nom"
          size="lg"
          d-placeholder="Nom"
          custom-class="w-full"
          :d-model.sync="user.firstName"
        ></dok-input>
        <dok-input
          label="Prénom"
          size="lg"
          d-placeholder="Prénom"
          custom-class="w-full"
          :d-model.sync="user.lastName"
        ></dok-input>
        <div class="w-full flex flex-col gap-y-1">
          <label class="font-EffraR text-base text-gray-800"
            >Date de naissance</label
          >
          <birth-day
            :date-save="user.birthday"
            :on-set-date="onSetBirthday"
            custom-class="border-2 border-solid border-borderInput rounded px-1 font-EffraR text-graylabel placeholder-graylabel w-full h-[48px]"
          ></birth-day>
        </div>
        <dok-input
          label="Ville de naissance"
          size="lg"
          d-placeholder="Ville de naissance"
          custom-class="w-full"
          :d-model.sync="user.birthplace"
        ></dok-input>
        <dok-input
          label="Téléphone mobile"
          size="lg"
          d-placeholder="Téléphone mobile"
          custom-class="w-full"
          :d-model.sync="user.mobile"
        ></dok-input>
        <dok-input
          label="Profession"
          size="lg"
          d-placeholder="Profession"
          custom-class="w-full"
          :d-model.sync="user.job"
        ></dok-input>
        <dok-textarea
          label="Adresse"
          size="lg"
          :re-size="true"
          :d-model.sync="user.landingPage.address.label"
        ></dok-textarea>
        <dok-select
          size="lg"
          label="Etat civil"
          :d-model.sync="user.relationship"
        >
          <template v-slot:options>
            <option value="MARRIED">Marié(e)</option>
            <option value="SINGLE">Célibataire</option>
            <option value="DIVORCED">Divorcé(e)</option>
            <option value="WIDOWER">veuf(ve)</option>
          </template>
          <template v-slot:rightIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="#111"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4.5 6.75l4.5 4.5 4.5-4.5"
              />
            </svg>
          </template>
        </dok-select>
      </template>
      <!------------Assurance---------------->
      <template v-if="page === 2">
        <dok-input
          label="N° d'affliation CNSS"
          size="lg"
          d-placeholder="N° D'affliation CNSS"
          custom-class="w-full"
          :d-model.sync="user.CNSS"
        ></dok-input>
        <dok-input
          label="Nom de l'assurance complémentaire"
          size="lg"
          d-placeholder="Nom de l'assurance complémentaire"
          custom-class="w-full"
          :d-model.sync="user.insurance.name"
        ></dok-input>
        <dok-input
          label="N° de police à l'assurance complémentaire"
          size="lg"
          d-placeholder="N° de police à l'assurance complémentaire"
          custom-class="w-full"
          :d-model.sync="user.insurance.numPolice"
        ></dok-input>
        <dok-input
          label="N° d'adhésion à l'assurance complémentaire"
          size="lg"
          d-placeholder="N° d'adhésion à l'assurance complémentaire"
          custom-class="w-full"
          :d-model.sync="user.insurance.numAdhesion"
        ></dok-input>
      </template>
    </div>

    <div id="actions" class="flex flex-row justify-between px-8">
      <dok-button size="md" bg="gray" custom-class="uppercase px-8">
        Ignorer
      </dok-button>
      <dok-button
        size="md"
        bg="rose"
        custom-class="uppercase px-8"
        @click.native="onSave"
      >
        Sauvegarder
      </dok-button>
    </div>

    <modal
      :is-active="modal.is"
      :exit-modal="() => {}"
      :hidden-header="true"
      :no-padding="true"
      :width="600"
      :has-no-scroll="true"
    >
      <div class="p-5">
        <div class="flex flex-col gap-y-3">
          <div class="flex justify-between items-center">
            <div
              class="flex gap-x-2 items-center text-black font-EffraR text-2xl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 2H7c-1.105 0-2 .895-2 2v16c0 1.105.895 2 2 2h10c1.105 0 2-.895 2-2V4c0-1.105-.895-2-2-2z"
                />
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M12 18h.01"
                />
              </svg>

              Téléchargez l'app pour plus de fonctionnalités
            </div>
            <svg
              @click="
                () => {
                  modal.is = false;
                }
              "
              class="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M18 6L6 18M6 6l12 12"
              />
            </svg>
          </div>
          <!-- <p class="text-base font-EffraR text-[#515151] leading-loose">
            Afin de débloquer cette fonctonnalité et
          </p> -->
          <p class="text-sm font-EffraR text-black">
            Obtenir un lien de téléchargement
          </p>
        </div>
        <down-app :delete-classes="true" :no-text="true" />
      </div>
    </modal>
  </section>
</template>

<script>
import { EventBus } from "@/event-bus";
const modal = () => import("@/views/global-components/modal.vue");
const downApp = () =>
  import("@/layouts/home/layouts/navbar/layouts/downloadApp.vue");
const birthDay = () => import("@/views/global-components/birthday");
const phoneActivation = () =>
  import("@/views/global-components/phoneVerfication.vue");

export default {
  components: { modal, downApp, birthDay, phoneActivation },
  data() {
    return {
      page: 1,
      user: this.$auth.getUserInfo(),
      modal: { is: false }
    };
  },
  methods: {
    openMobileNav() {
      EventBus.$emit("Change_Page_Number_My_Account_Patient", -1);
    },
    onSetBirthday(date) {
      this.user.birthday = date;
    },
    async onSave() {
      this.$vs.loading();
      let checkMobile = this.user.mobile !== this.$auth.getUserInfo().mobile;

      await this.$store.dispatch("user/UPDATE_PROFILE", {
        data: { ...this.user },
        onData: ({ ok, data, message }) => {
          if (ok) {
            if (checkMobile) {
              EventBus.$emit("Call_Phone_Verification");
            }

            this.$auth.setUserInfo(data);
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Modification effectuée avec succès!",
              timerAlert: 8000
            });

            this.$nextTick(() => {
              this.$vs.loading.close();
              return;
            });
          } else {
            EventBus.$emit("flashDok", {
              type: "errorr",
              message: message,
              timerAlert: 8000
            });

            this.$nextTick(() => {
              this.$vs.loading.close();
              return;
            });
          }
        }
      });
    }
  }
};
</script>
